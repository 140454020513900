/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/order */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { GlobalState } from 'index'

import {
  FaUserTie,
  FaEnvelopeOpenText,
  FaUserEdit,
  FaUserClock,
  FaRegListAlt,
  FaUserShield,
  FaUserNurse,
  FaUserFriends,
  FaRegFilePdf,
  FaWhmcs,
} from 'react-icons/fa'

import { NavMenuType } from 'App'

import {
  StyledSidebarItem,
  StyledTitle,
  StyledSubMenuItemWrapper,
  StyledSubMenuItemTitle,
} from './NavMenu.styles'

import { INavMenu, ISubMenu } from '../types'
import { LoanType } from 'features/loans/types'
import {
  AGENTS,
  UNDERWRITER,
  SDL,
  CONTRACTS,
  WAITING,
  OPERATOR,
  COLLECTOR,
  CLIENTS,
  SETTINGS,
} from '../structure'

import { fetchAgentLoansCount } from 'features/agentLoans/extra'
import { fetchContractsCount } from 'features/contracts/extra'
import { fetchLoansCount } from 'features/loans/extra'

import { UNDERWRITER_LOAN_BAN_LIST, UNDERWRITER_LOAN_BLACK_LIST } from '../../../constants'

import { useWindowSize } from 'helpers'
import { NavigationContext } from 'app/contexts'

import { SettingsType } from 'features/agentLoans/types'

const IMAGES = {
  underwriter: <FaUserTie />,
  agents: <FaUserEdit />,
  waiting: <FaUserClock />,
  clients: <FaUserFriends />,
  contracts: <FaRegListAlt />,
  sdl: <FaEnvelopeOpenText />,
  operator: <FaUserShield />,
  collector: <FaUserNurse />,
  settings: <FaWhmcs />,
}

export const NavMenu = (props: INavMenu): JSX.Element => {
  const { width } = useWindowSize()
  const { opened, setOpened } = props
  const dispatch = useDispatch()
  const [mobileView, setMobileView] = useState<boolean>()

  const {
    isDevelopment,
    isSuperUser,
    isAgentRole,
    isUnderwriterRole,
    navMenuType,
    isOperatorRole,
    isCollectorRole,
  } = useContext(NavigationContext)

  const { pathname } = useLocation<{ pathname: string }>()

  const {
    totalAmount: { all, processing, unassigned },
  } = useSelector((store: GlobalState) => store.loans)
  const {
    totalAmount: { all_app, waiting, on_check, agent_in_work, setting_a_limit, loan_approved },
  } = useSelector((store: GlobalState) => store.agentLoans)
  const {
    totalAmount: { all_contracts, active, overdue },
  } = useSelector((store: GlobalState) => store.contracts)
  const { clients } = useSelector((store: GlobalState) => store.clients)
  const {
    statusesLoan: { is_free: free, is_in_work: inWork },
  } = useSelector((store: GlobalState) => store.singleLoan)

  useEffect(() => {
    if (width >= 480) setMobileView(false)
    else setMobileView(true)
  }, [width])

  useEffect(() => {
    if (isAgentRole !== null && isAgentRole && navMenuType === NavMenuType.agent) {
      dispatch(fetchAgentLoansCount())
      dispatch(fetchContractsCount())
    }
    if (
      isUnderwriterRole !== null &&
      isUnderwriterRole &&
      navMenuType === NavMenuType.underwriter
    ) {
      dispatch(fetchLoansCount())
    }
  }, [isAgentRole, isUnderwriterRole, navMenuType])

  const isActive = (itemUrl: string): boolean => pathname.includes(itemUrl)

  const totalCounts = {
    underwriter: {
      all,
      processing,
      unassigned,
    },
    operator: {
      all,
      processing,
      unassigned,
    },
    agents: {
      all_app,
      on_check,
      agent_in_work,
      setting_a_limit,
      loan_approved,
    },
    waiting: { waiting },
    clients: { clients },
    contracts: { all_contracts, active, overdue },
    sdl: {},
    collector: {},
    settings: {},
  }

  const menuProps = {
    isActive,
    opened,
    totalCounts,
    setOpened,
    mobileView,
  }

  return (
    <>
      {isUnderwriterRole && navMenuType === NavMenuType.underwriter && (
        <MenuItem items={UNDERWRITER} {...menuProps} />
      )}
      {isAgentRole && navMenuType === NavMenuType.agent && (
        <>
          <MenuItem items={AGENTS} {...menuProps} />
          <MenuItem items={WAITING} {...menuProps} />
          <MenuItem items={CONTRACTS} {...menuProps} />
          <MenuItem items={CLIENTS} {...menuProps} />
        </>
      )}
      {isOperatorRole && navMenuType === NavMenuType.operator && (
        <>
          <MenuItem items={OPERATOR} {...menuProps} />
        </>
      )}
      {isCollectorRole && navMenuType === NavMenuType.collector && (
        <>
          <MenuItem items={COLLECTOR} {...menuProps} />
        </>
      )}
      {(isSuperUser || isDevelopment) && navMenuType === NavMenuType.sdl && (
        <>
          <MenuItem items={SDL} {...menuProps} />
        </>
      )}
      {(isSuperUser || isDevelopment) && navMenuType === NavMenuType.settings && (
        <>
          <MenuItem items={SETTINGS} {...menuProps} />
        </>
      )}
    </>
  )
}

const MenuItem = ({ items, isActive, opened, totalCounts, setOpened, mobileView }) => {
  const active = isActive(items.url)

  return (
    <StyledSidebarItem active={active} opened={opened} key={items.id}>
      <Link to={items.url} onClick={() => (mobileView ? setOpened(true) : null)}>
        {IMAGES[items.name]}
        <StyledTitle>{items.title}</StyledTitle>
      </Link>
      <SubMenu
        mobileView={mobileView}
        setOpened={setOpened}
        list={items.name}
        data={items.data}
        totalCounts={totalCounts[items.name]}
        activeList={active}
        isExpanded
        opened={opened}
        parentUrl={items.url}
      />
    </StyledSidebarItem>
  )
}

const SubMenu = (props: ISubMenu): JSX.Element => {
  const { isExpanded, opened, data, parentUrl, activeList, totalCounts, setOpened, mobileView } =
    props

  const location: any = useLocation()
  const history = useHistory()

  const {
    statusesLoan: { is_free: free, is_in_work: inWork },
  } = useSelector((store: GlobalState) => store.singleLoan)

  const {
    navigation: { slug },
  } = useContext(NavigationContext)

  const [activeType, setActiveType] = useState<string | null>(null)

  const conditionsTypes = {
    agents: {
      free,
      inWork,
    },
    underwriter: {},
    contracts: {},
    operator: {},
    settings: {},
  }

  useEffect(() => {
    // exception for ban-list
    if (location.pathname === UNDERWRITER_LOAN_BAN_LIST) {
      setActiveType(LoanType.banned)
      return
    }
    // exception for black-list
    if (location.pathname === UNDERWRITER_LOAN_BLACK_LIST) {
      setActiveType(LoanType.blacklist)
      return
    }

    if (!slug) {
      setActiveType(location.state?.type)
    }

    if (slug === 'underwriter') {
      if (free) setActiveType(LoanType.unassigned)
      if (inWork) setActiveType(LoanType.processing)
      if (!free && !inWork) setActiveType(LoanType.all)
    }
    if (slug === 'operator') {
      if (free) setActiveType(LoanType.unassigned)
      if (inWork) setActiveType(LoanType.processing)
      if (!free && !inWork) setActiveType(LoanType.all)
    }
  }, [conditionsTypes, location])

  const setTypeHandler = (currentType: string) => (): void => {
    if (currentType === LoanType.banned) {
      history.push({
        pathname: UNDERWRITER_LOAN_BAN_LIST,
      })
      return
    }
    if (currentType === LoanType.blacklist) {
      history.push({
        pathname: UNDERWRITER_LOAN_BLACK_LIST,
      })
      return
    }

    if (currentType === SettingsType.lids) {
      history.push({
        pathname: `/settings/lids`,
      })
      return
    }

    if (currentType === SettingsType.documents) {
      history.push({
        pathname: `/settings/documents`,
      })
      return
    }

    const params = new URLSearchParams()

    history.push({
      pathname: parentUrl,
      search: params.toString(),
      state: { type: currentType },
    })

    if (mobileView) setOpened(true)
  }

  return (
    <StyledSubMenuItemWrapper opened={opened} isExpanded={isExpanded}>
      {data.map((item) => {
        return (
          <StyledSubMenuItemTitle
            key={item.id}
            active={activeList && item.name === activeType}
            onClick={setTypeHandler(item.name)}
          >
            <p>{item.title}</p>
            <span>{totalCounts[item.name]}</span>
          </StyledSubMenuItemTitle>
        )
      })}
    </StyledSubMenuItemWrapper>
  )
}
