/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgentLoansService } from 'services/agentLoanService'
import { ProjectLoansService } from 'services/loanService'
import { ProjectOperatorService } from 'services/operatorService'
import { ProjectContractsService } from '../../services/contractsService'
import { ContractStatusType, ContractType } from './types'

export const fetchContracts = createAsyncThunk<
  any,
  { iteration: number; type: string; filters: any; refresh?: boolean }
>('contracts/loadContracts', async (props) => {
  const { iteration, type, filters, refresh = false } = props
  const response = await ProjectContractsService.getContracts(iteration, {
    ...filters,
    loan_status: ContractStatusType[type],
  }).then(({ data }) => ({
    data,
    type,
    refresh,
    iteration,
  }))
  return response
})

export const fetchContractsCount = createAsyncThunk<any>('contracts/loadCount', async () => {
  const { loans_count: all } = await ProjectContractsService.getAllContractsCount().then(
    ({ data }) => data
  )
  const { loans_count: active } = await ProjectContractsService.getActiveContractsCount().then(
    ({ data }) => data
  )
  const { loans_count: overdue } = await ProjectContractsService.getOverdueContractsCount().then(
    ({ data }) => data
  )
  const response = {
    [ContractType.allContracts]: all,
    [ContractType.active]: active,
    [ContractType.overdue]: overdue,
  }
  return response
})

export const fetchContractsUserPassportData = createAsyncThunk<any, { userId: string }>(
  'contracts/fetchUserPassport',
  async (props) => {
    const { userId } = props
    const response = await ProjectContractsService.getPassportData(userId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchContractsUserPersonalData = createAsyncThunk<any, { userId: string }>(
  'contracts/getPersonalData',
  async (props) => {
    const { userId } = props
    const response = await ProjectContractsService.getPersonalData(userId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchLoanPageById = createAsyncThunk<any, { id }>(
  'contracts/loadPageById',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getLoanPageById(id).then(({ data }) => data)
    return response
  }
)

export const fetchPersonalDataPhoto = createAsyncThunk<any, { id }>(
  'contracts/loadPersonalPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPersonalDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchRegistrationDataPhoto = createAsyncThunk<any, { id }>(
  'contracts/loadRegistrationPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getRegistrationDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchAgentDataPhoto = createAsyncThunk<any, { id }>(
  'contracts/loadAgentPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getAgentDataPhoto(id).then(({ data }) => data) // TODO : CHANGE
    // const response = await ProjectLoansService.getRegistrationDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchContractPassportSelfieDataPhoto = createAsyncThunk<any, { userId }>(
  'contracts/fetchContractPassportSelfieDataPhoto',
  async (props) => {
    const { userId } = props
    const response = await ProjectLoansService.getPassportSelfieDataPhoto(userId).then(
      ({ data }) => data
    )
    return response
  }
)

export const fetchPhonesData = createAsyncThunk<any, { id }>(
  'contracts/loadPhonesData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPhonesData(id).then(({ data }) => data)
    return response
  }
)

export const fetchUserLoans = createAsyncThunk<any, { ids: string[] }>(
  'contracts/userLoans',
  async ({ ids }) => {
    const response = await ProjectLoansService.getDetailedLoansByUserId(ids).then(
      ({ data }) => data
    )
    return response?.data
  }
)

export const fetchLoanDebt = createAsyncThunk<any, { loanId: string }>(
  'contracts/fetchLoanDebt',
  async (props) => {
    const { loanId } = props
    const response = await ProjectOperatorService.getLoanDebt(loanId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchLoanTransactions = createAsyncThunk<any, { loan_number: string }>(
  'contracts/fetchLoanTransactions',
  async (props) => {
    const { loan_number } = props
    const response = await ProjectOperatorService.getLoanTransactions(loan_number).then(
      ({ data }) => ({
        data,
      })
    )
    return response
  }
)

export const getLoanComments = createAsyncThunk<any, { loanNumber: string }>(
  'contracts/getComments',
  async (props) => {
    const { loanNumber } = props
    const response = await ProjectAgentLoansService.getLoanAgentComments(loanNumber).then(
      ({ data }) => data
    )
    return response
  }
)

export const createLoanComments = createAsyncThunk<
  any,
  {
    loanNumber: string
    comment: string
    successAction?: () => void
    errorAction?: () => void
  }
>('contracts/createComment', async (props) => {
  const { loanNumber, comment, successAction, errorAction } = props
  const response = await ProjectAgentLoansService.createLoanAgentComments(loanNumber, comment)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})

export const updateLoanComments = createAsyncThunk<
  any,
  { commentId: string; comment: string; successAction?: () => void; errorAction?: () => void }
>('contracts/updateComment', async (props) => {
  const { commentId, comment, successAction, errorAction } = props
  const response = await ProjectAgentLoansService.updateLoanComments(commentId, comment)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})

export const getPaymentsResult = createAsyncThunk<any, { id: string }>(
  'contracts/getPaymentsResult',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPayments(id).then(({ data }) => data)
    return response
  }
)
