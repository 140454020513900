/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

interface IFilterState {
  data: {
    [key: string]: string | any
    search?: string
  }
}

export const initialFiltersState: IFilterState = {
  data: {},
}

export const clientFiltersSlice = createSlice({
  name: 'clientFilters',
  initialState: initialFiltersState,
  reducers: {
    setClientFilters: (state, { payload }) => {
      state.data = payload
    },
  },
})

export const { setClientFilters } = clientFiltersSlice.actions

export default clientFiltersSlice.reducer
