/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { NavMenuType } from 'App'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { fetchClients } from 'features/clients/extra'
import { resetClients } from 'features/clients/clientsSlice'
import { CLIENTS_ROUTE, UNDERWRITER_LOANS_ROUTE } from 'constants/pagesRoutes'
import { Controls } from '../../components/Controls'
import { StyledPageWrapper } from './ClientsList.styles'

import { Table } from './Table'
import { NavigationContext } from 'app/contexts'
import { ClientTypeValue } from 'features/clients/types'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const ClientsList = (): JSX.Element => {
  const q = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const { setNavigation, isAgentRole, setNavMenuType } = useContext(NavigationContext)

  const [type, setType] = useState(null)
  const [showFilters, setShowFilters] = useState<boolean>(false)

  useEffect(() => {
    if (isAgentRole === false) {
      history.push(UNDERWRITER_LOANS_ROUTE)
      setNavMenuType(NavMenuType.underwriter)
    } else setNavMenuType(NavMenuType.agent)
  }, [isAgentRole])

  useEffect(() => {
    const params = new URLSearchParams()
    if (type) {
      params.append('type', type)
      history.push({
        pathname: CLIENTS_ROUTE,
        search: params.toString(),
        state: { type },
      })
    }
  }, [type, history])

  const { clients, loadable }: any = useSelector((store: GlobalState) => store.clients)

  useEffect(() => {
    const queryType = q.get('type')
    setType(queryType ? ClientTypeValue[queryType] : ClientTypeValue.all)
  }, [])

  useEffect(() => {
    if (type) dispatch(fetchClients({ type: type ? type : 'all' }))
  }, [type])

  useEffect(() => {
    dispatch(resetClients(null))
  }, [])

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Список клиентов`,
      backTo: false,
    })
  }, [])

  const toggleFilters = (): void => {
    setShowFilters(!showFilters)
  }

  const filterProps = {
    pageControlsStructure: 'clients',
    filtersVisible: false,
    toggleFilters,
    showFilters,
    showSearch: false,
    showCounters: false,
    type,
    setType,
    pending: loadable,
  }

  return (
    <StyledPageWrapper>
      <Controls {...filterProps} />
      <Table clients={clients} type={type} loadable={loadable} />
    </StyledPageWrapper>
  )
}
