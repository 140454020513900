/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { Module } from 'components'

import { normalizeValues } from 'helpers'

import {
  StyledHeadTitle,
  StyledLoanHistoryContent,
  StyledHistoryColumnWrapper,
  StyledHistoryMobileItem,
  StyledItemTitle,
  StyledDescription,
  StyledHistoryMobileTableWrapper,
} from './Modules.styles'

const moduleId = 'services'

export const Services: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const { services, servicesPending } = useSelector((store: GlobalState) => store.singleLoan)
  const isOpen = openedModules.includes(moduleId)
  const [servicesData, setServicesData] = useState([])

  const handleExpand = (): void => onToggleModule(moduleId)

  useEffect(() => {
    if (services) setServicesData(services)
  }, [services])

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <StyledHeadTitle>
            Услуги
            {!servicesData.length ? (
              <span style={{ color: servicesPending ? '#2333cb' : 'gray', opacity: '0.5' }}>
                / {servicesPending ? 'запрашиваются' : 'отсутствуют'}
              </span>
            ) : null}
          </StyledHeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {servicesData && servicesData.length ? (
          <StyledLoanHistoryContent>
            <TableHead />
          </StyledLoanHistoryContent>
        ) : null}

        {servicesData && servicesData.length
          ? servicesData.map((item, index) => (
              <StyledHistoryColumnWrapper key={item?.loan_id || index}>
                <StyledLoanHistoryContent>
                  <Table {...item} key={item?.loan_id || index} />
                </StyledLoanHistoryContent>
              </StyledHistoryColumnWrapper>
            ))
          : null}

        {servicesData && servicesData.length
          ? servicesData.map((item, index) => {
              return (
                <StyledHistoryMobileTableWrapper key={index}>
                  <StyledHistoryMobileItem>
                    <StyledItemTitle>Дата</StyledItemTitle>
                    <StyledDescription>
                      {normalizeValues(item?.date, 'source_full_date')}
                    </StyledDescription>

                    <StyledItemTitle>Название услуги</StyledItemTitle>
                    <StyledDescription>
                      {serviceTypes[item?.service_name]
                        ? serviceTypes[item?.service_name]
                        : item?.service_name}
                    </StyledDescription>

                    <StyledItemTitle>Статус</StyledItemTitle>
                    <StyledDescription>
                      {serviceStatuses[item?.active] ? serviceStatuses[item?.active] : item?.active}
                    </StyledDescription>

                    <StyledItemTitle>Сумма услуги</StyledItemTitle>
                    <StyledDescription>
                      {item?.amount ? `${item?.amount.toLocaleString('ru-RU')}  ₽` : ''}
                    </StyledDescription>

                    <StyledItemTitle>Долг на сейчас</StyledItemTitle>
                    <StyledDescription>
                      {item?.debt ? `${item?.debt.toLocaleString('ru-RU')}  ₽` : ''}
                    </StyledDescription>
                  </StyledHistoryMobileItem>
                </StyledHistoryMobileTableWrapper>
              )
            })
          : null}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Название услуги</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Статус</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Сумма услуги</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Долг на сейчас</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const {
    amount: serviceSum,
    service_name: type,
    active: status,
    date: addedAt,
    debt: paySum,
  } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(addedAt, 'source_full_date')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{serviceTypes[type] ? serviceTypes[type] : type}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {serviceStatuses[status] ? serviceStatuses[status] : status}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {serviceSum ? `${serviceSum.toLocaleString('ru-RU')}  ₽` : ''}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {paySum ? `${paySum.toLocaleString('ru-RU')}  ₽` : ''}
        </Module.TableValue>
      </Module.TableColumn>
    </>
  )
}

const serviceTypes = {
  payment_service_accelerated_review: 'Премиум',
  extra_service: 'Страховка',
}

const serviceStatuses = {
  service_is_activated: 'Услуга активирована',
  service_is_deactivated: 'Услуга деактивирована',
  service_is_not_activated: 'Услуга не активирована',
  service_is_rejected: 'Услуга отменена',
  service_paid: 'Услуга оплачена',
}
