/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'

import { Button, Select, Module } from 'components'
import { useGetLoanPassportData, useGetLoanPersonalData } from 'features/loan/loanSelectors'
import { IPersonalData } from 'features/loan/types'
import {
  fetchFemidaPassport,
  fetchFemidaPhone,
  fetchFemidaScoringFraudPhone,
  fetchFemidaScoringLegal,
  fetchFemidaScoringOneTimePhone,
  fetchPersonalData,
  updatePersonalData,
} from 'features/loan/extra'
import {
  InputField,
  calculateAge,
  declOfNum,
  isValidForm,
  normalizeValues,
  timeZoneMask,
  validationSchemaPersonal,
} from 'helpers'

import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'
import { maritalStatus } from 'helpers/dictionary'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import {
  StyledSelectBlock,
  StyledRefresh,
  StyledButtonBlock,
  StyledFemidsDate,
} from './Modules.styles'
import { IModuleProps } from './types'
import { GlobalState } from 'index'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

const moduleId = 'femida'
export const Femida: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  userId,
  loanNumber,
}): JSX.Element => {
  const dispatch = useDispatch()
  const [editable, setEditable] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean | string>(false)
  const [initialValues, setInitialValues] = useState<IPersonalData | unknown>({})
  const { femida } = useSelector((store: GlobalState) => store.singleLoan)

  const isOpen = openedModules.includes(moduleId)
  const {
    first_name: firstName = '',
    last_name: lastName = '',
    middle_name: middleName = '',
    date_of_birth: birthDate,
    serial,
    number,
  } = useGetLoanPassportData()
  const { login } = useGetLoanPersonalData()

  useEffect(() => {
    if (isOpen && userId) {
      dispatch(
        fetchFemidaPhone({
          data: {
            lastname: lastName,
            firstname: firstName,
            middlename: middleName,
            birthday: moment(new Date(birthDate)).format('DD.MM.YYYY'),
            phone_number: login.replace('+', ''),
          },
          userId,
          number: loanNumber,
          force: false,
        })
      )
      dispatch(
        fetchFemidaPassport({
          data: {
            lastname: lastName,
            firstname: firstName,
            middlename: middleName,
            birthday: moment(new Date(birthDate)).format('DD.MM.YYYY'),
            passport_series_and_number: `${serial}${number}`,
          },
          userId,
          number: loanNumber,
          force: false,
        })
      )

      dispatch(
        fetchFemidaScoringLegal({
          data: {
            lastname: lastName,
            firstname: firstName,
            middlename: middleName,
            birthday: moment(new Date(birthDate)).format('DD.MM.YYYY'),
            phone_number: login.replace('+', ''),
          },
          userId,
          number: loanNumber,
          force: false,
        })
      )
      dispatch(
        fetchFemidaScoringOneTimePhone({
          data: {
            phone_number: login.replace('+', ''),
          },
          userId,
          number: loanNumber,
          force: false,
        })
      )
      dispatch(
        fetchFemidaScoringFraudPhone({
          data: {
            phone_number: login.replace('+', ''),
          },
          userId,
          number: loanNumber,
          force: false,
        })
      )
    }
  }, [isOpen, userId])

  const refreshFemidaPhone = async () => {
    if (pending) return
    setPending('refreshFemidaPhone')
    await dispatch(
      fetchFemidaPhone({
        data: {
          lastname: lastName,
          firstname: firstName,
          middlename: middleName,
          birthday: moment(new Date(birthDate)).format('DD.MM.YYYY'),
          phone_number: login.replace('+', ''),
        },
        userId,
        number: loanNumber,
        force: true,
      })
    )
    setPending(false)
  }

  const refreshFemidaPassport = async () => {
    if (pending) return
    setPending('refreshFemidaPassport')
    await dispatch(
      fetchFemidaPassport({
        data: {
          lastname: lastName,
          firstname: firstName,
          middlename: middleName,
          birthday: moment(new Date(birthDate)).format('DD.MM.YYYY'),
          passport_series_and_number: `${serial}${number}`,
        },
        userId,
        number: loanNumber,
        force: true,
      })
    )
    setPending(false)
  }
  const refreshFemidaScoringLegal = async () => {
    if (pending) return
    setPending('refreshFemidaScoringLegal')
    await dispatch(
      fetchFemidaScoringLegal({
        data: {
          lastname: lastName,
          firstname: firstName,
          middlename: middleName,
          birthday: moment(new Date(birthDate)).format('DD.MM.YYYY'),
          phone_number: login.replace('+', ''),
        },
        userId,
        number: loanNumber,
        force: true,
      })
    )
    setPending(false)
  }
  const refreshFemidaScoringOneTimePhone = async () => {
    if (pending) return
    setPending('refreshFemidaScoringOneTimePhone')
    await dispatch(
      fetchFemidaScoringOneTimePhone({
        data: {
          phone_number: login.replace('+', ''),
        },
        userId,
        number: loanNumber,
        force: true,
      })
    )
    setPending(false)
  }
  const refreshFemidaScoringFraudPhone = async () => {
    if (pending) return
    setPending('refreshFemidaScoringFraudPhone')
    await dispatch(
      fetchFemidaScoringFraudPhone({
        data: {
          phone_number: login.replace('+', ''),
        },
        userId,
        number: loanNumber,
        force: true,
      })
    )
    setPending(false)
  }

  const handleExpand = (): void => onToggleModule(moduleId)

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={null}
      validationSchema={validationSchemaPersonal}
    >
      {(formikProps) => {
        const { dirty } = formikProps

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Femida</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <>
                <Module.Content>
                  {/* {data.isFetching && <Module.LoaderBlock />} */}
                  <View
                    {...formikProps}
                    femida={femida}
                    pending={pending}
                    functions={{
                      refreshFemidaPhone,
                      refreshFemidaPassport,
                      refreshFemidaScoringLegal,
                      refreshFemidaScoringOneTimePhone,
                      refreshFemidaScoringFraudPhone,
                    }}
                  />
                </Module.Content>
              </>
            </Module.ContentWrapper>
            <StyledButtonBlock />
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const View = (props): JSX.Element => {
  const { femida, functions, pending } = props

  return (
    <>
      <>
        <Module.Column column={1}>
          <Module.Title style={{ display: 'flex' }}>
            Связка ФИО+ДР+Телефон
            <StyledRefresh>
              <Button
                type='smallSuccess'
                onClick={functions?.refreshFemidaPhone}
                style={{ padding: '0 6px' }}
                disabled={pending === 'refreshFemidaPhone'}
              >
                Запросить
              </Button>
            </StyledRefresh>
          </Module.Title>
          <Module.Value>
            {pending === 'refreshFemidaPhone' ? 'запрашивается...' : femida?.phone?.text}
          </Module.Value>
          <Module.Value>
            <StyledFemidsDate>
              {normalizeValues(femida?.phone?.registered_in, 'date')}
            </StyledFemidsDate>
          </Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title style={{ display: 'flex' }}>
            Связка ФИО+ДР+Паспорт
            <StyledRefresh>
              <Button
                type='smallSuccess'
                onClick={functions?.refreshFemidaPassport}
                style={{ padding: '0 6px' }}
                disabled={pending === 'refreshFemidaPassport'}
              >
                Запросить
              </Button>
            </StyledRefresh>
          </Module.Title>
          <Module.Value>
            {pending === 'refreshFemidaPassport' ? 'запрашивается...' : femida?.passport?.text}
          </Module.Value>
          <Module.Value>
            <StyledFemidsDate>
              {normalizeValues(femida?.passport?.registered_in, 'date')}
            </StyledFemidsDate>
          </Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title style={{ display: 'flex' }}>
            Скоринг благонадежности
            <StyledRefresh>
              <Button
                type='smallSuccess'
                onClick={functions?.refreshFemidaScoringLegal}
                style={{ padding: '0 6px' }}
                disabled={pending === 'refreshFemidaScoringLegal'}
              >
                Запросить
              </Button>
            </StyledRefresh>
          </Module.Title>
          <Module.Value>
            {pending === 'refreshFemidaScoringLegal'
              ? 'запрашивается...'
              : femida?.scoringLegal?.text}
          </Module.Value>
          <Module.Value>
            <StyledFemidsDate>
              {normalizeValues(femida?.scoringLegal?.registered_in, 'date')}
            </StyledFemidsDate>
          </Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title style={{ display: 'flex' }}>
            Одноразовый телефон
            <StyledRefresh>
              <Button
                type='smallSuccess'
                onClick={functions?.refreshFemidaScoringOneTimePhone}
                style={{ padding: '0 6px' }}
                disabled={pending === 'refreshFemidaScoringOneTimePhone'}
              >
                Запросить
              </Button>
            </StyledRefresh>
          </Module.Title>
          <Module.Value>
            {pending === 'refreshFemidaScoringOneTimePhone'
              ? 'запрашивается...'
              : femida?.scoringOneTimePhone?.text}
          </Module.Value>
          <Module.Value>
            <StyledFemidsDate>
              {normalizeValues(femida?.scoringOneTimePhone?.registered_in, 'date')}
            </StyledFemidsDate>
          </Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title style={{ display: 'flex' }}>
            Мошеннический телефон
            <StyledRefresh>
              <Button
                type='smallSuccess'
                onClick={functions?.refreshFemidaScoringFraudPhone}
                style={{ padding: '0 6px' }}
                disabled={pending === 'refreshFemidaScoringFraudPhone'}
              >
                Запросить
              </Button>
            </StyledRefresh>
          </Module.Title>
          <Module.Value>
            {pending === 'refreshFemidaScoringFraudPhone'
              ? 'запрашивается...'
              : femida?.scoringFraudPhone?.value}
          </Module.Value>
          <Module.Value>
            <StyledFemidsDate>
              {normalizeValues(femida?.scoringFraudPhone?.registered_in, 'date')}
            </StyledFemidsDate>
          </Module.Value>
        </Module.Column>
      </>
    </>
  )
}
