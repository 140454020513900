/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

const loanFields = [
  'loans.loan_date_create',
  // 'requests_count',
  'loans.loan_id',
  'loans.loan_number',
  'passport.first_name',
  'passport.last_name',
  'passport.middle_name',
  'users.login',
  // 'loans.loan_ask_sum',
  'loans.loan_sum',
  // 'passport.number',
  // 'passport.serial',
  // 'utm_source',
  // 'registration_address',
  'loan_statuses.status_name',
  // 'admin_username',
  // 'closed_count',
  'overdue_days',
  'agent_username',
  'agent_manager_username',
  'balance', // баланс
  'interest_debt', // Процентный долг
  'principal_debt', // основной долг
  'loans.product_id',
]

export class ProjectContractsService extends ServiceBase {
  protected static LOAN_PATH = 'loans'

  protected static USERS_PATH = 'users'

  protected static COUNT_PAGE_REQUEST = 20

  public static getContracts(iteration: number, filters: any): AxiosPromise {
    const data = JSON.stringify({
      fields: loanFields,
      ...filters,
    })

    return this.post(
      `${this.LOAN_PATH}/get?page_size=${this.COUNT_PAGE_REQUEST}&p=${iteration}`,
      data
    )
  }

  public static getPassportData(userId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/passport/${userId}`)
  }

  public static getPersonalData(userId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/personal-data/${userId}`)
  }

  public static getAllContractsCount(): AxiosPromise {
    return this.get(`agent_api/installment/contracts_count`)
  }

  public static getActiveContractsCount(): AxiosPromise {
    return this.get(`agent_api/installment/active_contracts_count`)
  }

  public static getOverdueContractsCount(): AxiosPromise {
    return this.get(`agent_api/installment/overdue_contracts_count`)
  }
}
