/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MouseEvent } from 'react'
import { keyBy } from 'lodash'

import { ReactComponent as MessagesSVG } from 'assets/messages.svg'
import { ReactComponent as CopySuccessSVG } from 'assets/copy_link_success.svg'

import { formatDate, loanStatuses, normalizeValues } from 'helpers'
import { CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'
import { AgentLoanStatus } from 'features/agentLoans/types'
import {
  // StyledMobileValue,
  StyledCounterMessageWrapper,
  StyledMobileHeaderAll,
  StyledMobileDescription,
  StyledMobileItemWrapper,
  StyledMobileLoanNumbers,
  StyledMobileSection,
  StyledStatus,
  StyledStatusWrapper,
  StyledStatusAllWrapper,
  StyledMobileTopAll,
  StyledValueCount,
  StyledMobileValue,
  StyledMobilePersonal,
  StyledMobileSum,
  StyledMobileStaff,
  StyledCopyLink,
  StyledMobileCopyLink,
  StyledMobileRequiredHead,
  StyledMobileCheckedHead,
  StyledCreateDate,
  StyledMobileDateCreate,
  StyledMobileDateValue,
  StyledStatusMobile,
  StyledHead,
  StyledMobileDividerSection,
} from './Table.styles'
import { Button } from '../../../components'

export const AllView = ({ loan, agentManagerName, agentName }): JSX.Element => (
  <StyledMobileItemWrapper>
    <StyledMobileSection>
      <StyledMobileHeaderAll>
        <StyledMobileTopAll>
          <StyledStatusWrapper>
            <StyledStatus status={loan.loan_statuses_status_name} />
            {loanStatuses[loan.loan_statuses_status_name]}
          </StyledStatusWrapper>
        </StyledMobileTopAll>
      </StyledMobileHeaderAll>
      <StyledMobileDescription>Номер заявки/Дата создания</StyledMobileDescription>
      <StyledMobileDateCreate>
        <StyledMobileLoanNumbers>{loan.loans_loan_number}</StyledMobileLoanNumbers>
        <StyledMobileDateValue>{formatDate(loan?.loans_loan_date_create)}</StyledMobileDateValue>
      </StyledMobileDateCreate>
    </StyledMobileSection>
    <StyledMobileSection>
      <StyledMobileDescription>ФИО</StyledMobileDescription>
      <StyledMobileValue>
        {loan?.passport_last_name} {loan?.passport_first_name} {loan?.passport_middle_name}
      </StyledMobileValue>
      <StyledMobilePersonal>
        <div>
          <StyledMobileDescription>Номер телефона</StyledMobileDescription>
          <StyledMobileValue>{loan.users_login}</StyledMobileValue>
        </div>
        <div>
          <StyledMobileDescription>Регион</StyledMobileDescription>
          <StyledMobileValue>{loan.residence_address}</StyledMobileValue>
        </div>
      </StyledMobilePersonal>
    </StyledMobileSection>
    <StyledMobileSection>
      <StyledMobileSum>
        <div>
          <StyledMobileDescription>Запрашиваемая сумма займа</StyledMobileDescription>
          <StyledMobileValue>
            {normalizeValues(loan.loans_loan_ask_sum, 'sum_without_symbol')}
          </StyledMobileValue>
        </div>
        <div>
          <StyledMobileDescription>Тип продукта</StyledMobileDescription>
          <StyledMobileValue>
            {CreditProductTranslate[CreditProductCodes[loan?.loans_product_id]]}
          </StyledMobileValue>
        </div>
      </StyledMobileSum>
    </StyledMobileSection>
    <StyledMobileSection>
      <StyledMobileSum>
        <div>
          <StyledMobileDescription>Руководитель</StyledMobileDescription>
          <StyledMobileValue>{agentManagerName}</StyledMobileValue>
        </div>
        <div>
          <StyledMobileDescription>Агент</StyledMobileDescription>
          <StyledMobileValue>{agentName}</StyledMobileValue>
        </div>
      </StyledMobileSum>
    </StyledMobileSection>
  </StyledMobileItemWrapper>
)

export const RequiredDocView: any = ({
  loan,
  onChangeAgent,
  agentManagerName,
  agentName,
}): JSX.Element => (
  <StyledMobileItemWrapper>
    <StyledMobileSection>
      <StyledMobileHeaderAll>
        <StyledMobileTopAll>
          <StyledStatusWrapper>
            <StyledStatus status={loan.loan_statuses_status_name} />
            {loanStatuses[loan.loan_statuses_status_name]}
          </StyledStatusWrapper>
        </StyledMobileTopAll>
      </StyledMobileHeaderAll>
      <StyledMobileDescription>Номер заявки/Дата создания</StyledMobileDescription>
      <StyledMobileDateCreate>
        <StyledMobileLoanNumbers>{loan.loans_loan_number}</StyledMobileLoanNumbers>
        <StyledMobileDateValue>{formatDate(loan?.loans_loan_date_create)}</StyledMobileDateValue>
      </StyledMobileDateCreate>
    </StyledMobileSection>
    <StyledMobileSection>
      <StyledMobileDescription>ФИО</StyledMobileDescription>
      <StyledMobileValue>
        {loan?.passport_last_name} {loan?.passport_first_name} {loan?.passport_middle_name}
      </StyledMobileValue>
      <StyledMobilePersonal>
        <div>
          <StyledMobileDescription>Номер телефона</StyledMobileDescription>
          <StyledMobileValue>{loan.users_login}</StyledMobileValue>
        </div>
        <div>
          <StyledMobileDescription>Регион</StyledMobileDescription>
          <StyledMobileValue>{loan.residence_address}</StyledMobileValue>
        </div>
      </StyledMobilePersonal>
    </StyledMobileSection>
    <StyledMobileSection>
      <StyledMobileSum>
        <div>
          <StyledMobileDescription>Запрашиваемая сумма займа</StyledMobileDescription>
          <StyledMobileValue>
            {normalizeValues(loan.loans_loan_ask_sum, 'sum_without_symbol')}
          </StyledMobileValue>
        </div>
        <div>
          <StyledMobileDescription>Тип продукта</StyledMobileDescription>
          <StyledMobileValue>
            {CreditProductTranslate[CreditProductCodes[loan?.loans_product_id]]}
          </StyledMobileValue>
        </div>
      </StyledMobileSum>
    </StyledMobileSection>
    <StyledMobileSection>
      <StyledMobileSum>
        <div>
          <StyledMobileDescription>Руководитель</StyledMobileDescription>
          <StyledMobileValue>{agentManagerName}</StyledMobileValue>
        </div>
        <div>
          <StyledMobileDescription>Агент</StyledMobileDescription>
          <StyledMobileValue>{agentName}</StyledMobileValue>
        </div>
      </StyledMobileSum>
    </StyledMobileSection>
    <StyledMobileSection>
      <Button type='border' onClick={onChangeAgent}>
        Передать клиента
      </Button>
    </StyledMobileSection>
  </StyledMobileItemWrapper>
)

export const CheckingView = ({ loan, agentManagerName, agentName }): JSX.Element => {
  return (
    <StyledMobileItemWrapper>
      <StyledMobileHeaderAll>
        <StyledMobileTopAll>
          <StyledStatusWrapper>
            <StyledStatusMobile status={loan.loan_statuses_status_name} />
            {loanStatuses[loan.loan_statuses_status_name]}
          </StyledStatusWrapper>
        </StyledMobileTopAll>
      </StyledMobileHeaderAll>

      <StyledMobileSection>
        <StyledMobileCheckedHead>
          <StyledHead>
            <div>
              <StyledMobileDescription>Номер заявки/Дата создания</StyledMobileDescription>
              <StyledMobileLoanNumbers>
                {normalizeValues(loan.loans_loan_number)}
              </StyledMobileLoanNumbers>
            </div>
            <StyledCreateDate>{formatDate(loan?.loans_loan_date_create)}!!!!!</StyledCreateDate>
          </StyledHead>
        </StyledMobileCheckedHead>
      </StyledMobileSection>

      <StyledMobileSection>
        <div>
          <StyledMobileDescription>ФИО клиента</StyledMobileDescription>
          <StyledMobileValue>
            {loan?.passport_last_name} {loan?.passport_first_name} {loan?.passport_middle_name}
          </StyledMobileValue>
        </div>
        <br />
        <StyledMobileDividerSection>
          <div>
            <StyledMobileDescription>Номер телефона</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(loan.users_login)}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Регион</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(loan.residence_address)}</StyledMobileValue>
          </div>
        </StyledMobileDividerSection>
      </StyledMobileSection>

      <StyledMobileSection>
        <StyledMobileDividerSection>
          <div>
            <StyledMobileDescription>Запрашиваемая сумма займа</StyledMobileDescription>
            <StyledMobileValue>
              {normalizeValues(loan.loans_loan_ask_sum, 'sum_without_symbol')}
            </StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Тип продукта</StyledMobileDescription>
            <StyledMobileValue>
              {CreditProductTranslate[CreditProductCodes[loan?.loans_product_id]]}
            </StyledMobileValue>
          </div>
        </StyledMobileDividerSection>
      </StyledMobileSection>

      <StyledMobileSection>
        <StyledMobileDividerSection>
          <div>
            <StyledMobileDescription>Руководитель</StyledMobileDescription>
            <StyledMobileValue>{agentManagerName}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Агент</StyledMobileDescription>
            <StyledMobileValue>{agentName}</StyledMobileValue>
          </div>
        </StyledMobileDividerSection>
      </StyledMobileSection>
    </StyledMobileItemWrapper>
  )
}

export const CheckedView = ({ loan, agentManagerName, agentName }): JSX.Element => {
  return (
    <StyledMobileItemWrapper>
      <StyledMobileHeaderAll>
        <StyledMobileTopAll>
          <StyledStatusWrapper>
            <StyledStatusMobile status={loan.loan_statuses_status_name} />
            {loanStatuses[loan.loan_statuses_status_name]}
          </StyledStatusWrapper>
        </StyledMobileTopAll>
      </StyledMobileHeaderAll>

      <StyledMobileSection>
        <StyledMobileCheckedHead>
          <StyledHead>
            <div>
              <StyledMobileDescription>Номер заявки/Дата создания</StyledMobileDescription>
              <StyledMobileLoanNumbers>
                {normalizeValues(loan.loans_loan_number)}
              </StyledMobileLoanNumbers>
            </div>
            <StyledCreateDate>{formatDate(loan?.loans_loan_date_create)}!!!!</StyledCreateDate>
          </StyledHead>
        </StyledMobileCheckedHead>
      </StyledMobileSection>

      <StyledMobileSection>
        <div>
          <StyledMobileDescription>ФИО клиента</StyledMobileDescription>
          <StyledMobileValue>
            {loan?.passport_last_name} {loan?.passport_first_name} {loan?.passport_middle_name}
          </StyledMobileValue>
        </div>
        <br />
        <StyledMobileDividerSection>
          <div>
            <StyledMobileDescription>Номер телефона</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(loan.users_login)}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Регион</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(loan.residence_address)}</StyledMobileValue>
          </div>
        </StyledMobileDividerSection>
      </StyledMobileSection>

      <StyledMobileSection>
        <StyledMobileDividerSection>
          <div>
            <StyledMobileDescription>Запрашиваемая сумма займа</StyledMobileDescription>
            <StyledMobileValue>
              {normalizeValues(loan.loans_loan_ask_sum, 'sum_without_symbol')}
            </StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Тип продукта</StyledMobileDescription>
            <StyledMobileValue>
              {CreditProductTranslate[CreditProductCodes[loan?.loans_product_id]]}
            </StyledMobileValue>
          </div>
        </StyledMobileDividerSection>
      </StyledMobileSection>

      <StyledMobileSection>
        <StyledMobileDividerSection>
          <div>
            <StyledMobileDescription>Руководитель</StyledMobileDescription>
            <StyledMobileValue>{agentManagerName}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Агент</StyledMobileDescription>
            <StyledMobileValue>{agentName}</StyledMobileValue>
          </div>
        </StyledMobileDividerSection>
      </StyledMobileSection>
    </StyledMobileItemWrapper>
  )
}
