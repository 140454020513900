import React, { useEffect, useState } from 'react'
import { ICheckBox } from './types'
import {
  StyledCheckboxContainerWrapper,
  StyledCheckboxContainer,
  StyledIcon,
  StyledCheckbox,
  StyledLabel,
} from './CheckBox.styles'

export const CheckBox: React.FC<ICheckBox> = ({
  active,
  onChange,
  value,
  disabled,
  label = null,
  size = 'small',
}) => {
  const [checked, setChecked] = useState<boolean>(active)

  const toggleCheckboxChange = () => {
    !disabled && onChange && onChange(value)
  }

  useEffect(() => {
    setChecked(active)
  }, [active])

  return (
    <StyledCheckboxContainerWrapper onClick={toggleCheckboxChange}>
      <StyledCheckboxContainer>
        <StyledCheckbox checked={checked} size={size}>
          <StyledIcon viewBox={size === 'small' ? '0 0 24 24' : '0 0 32 32'}>
            <polyline points={size === 'small' ? '20 6 9 17 4 12' : '28 8 12 24 6 18'} />
          </StyledIcon>
        </StyledCheckbox>
      </StyledCheckboxContainer>
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledCheckboxContainerWrapper>
  )
}
