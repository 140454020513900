/* eslint-disable @typescript-eslint/no-unused-vars */
import { SmallLoader } from 'components'
import {
  fetchPersonalAgentsDataPhoto,
  fetchAgentDataPhoto,
  fetchRegistrationAgentsDataPhoto,
  fetchAgentPassportSelfieDataPhoto,
} from 'features/agentLoans/extra'
import {
  fetchAddImage,
  fetchBankCardDataPhoto,
  fetchPassportSelfieDataPhoto,
  fetchPersonalDataPhoto,
  fetchRegistrationDataPhoto,
} from 'features/loan/extra'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  StyledImageWrapper,
  StyledImage,
  StyledPlus,
  StyledUploadImageLoaderWrapper,
  StyledTitle,
} from './UploadLoanImage.styles'
import { fetchContractPassportSelfieDataPhoto } from 'features/contracts/extra'

export const UploadLoanImage: any = ({
  type,
  userId,
  title = '',
  agents = false,
  contract = false,
  capture = null,
}) => {
  const dispatch = useDispatch()
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null)

  const input: any = useRef(null)
  const handleFiles = async (files) => {
    setFile(files[0])
  }
  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setPreview(reader.result)
      }
    }
  }, [file])

  useEffect(() => {
    if (preview) {
      toBase64(file).then(({ image, image_extension }) => {
        uploadImage({
          image,
          image_extension,
        })
      })
    }
  }, [preview])

  const uploadImage = ({ image, image_extension }) => {
    dispatch(
      fetchAddImage({
        image,
        image_type: type,
        image_extension,
        user_id: userId,
        onError: () => {
          setFile(null)
          setPreview(null)
        },
        onSuccess: () => {
          const args = { id: userId }
          if (agents) {
            switch (type) {
              case 'passport-main-page-photo':
                dispatch(fetchPersonalAgentsDataPhoto({ id: userId }))
                break
              case 'passport-registration-page-photo':
                dispatch(fetchRegistrationAgentsDataPhoto({ id: userId }))
                break
              case 'agent-photo':
                dispatch(fetchAgentDataPhoto({ id: userId }))
                break
              case 'passport-selfie':
                dispatch(fetchAgentPassportSelfieDataPhoto({ userId }))
                break
              default:
                break
            }
          }
          if (contract) {
            switch (type) {
              case 'passport-selfie':
                dispatch(fetchContractPassportSelfieDataPhoto({ userId }))
                break
              default:
                break
            }
          }
          if (!agents) {
            switch (type) {
              case 'bank-card-photo':
                dispatch(fetchBankCardDataPhoto(args))
                break
              case 'passport-registration-page-photo':
                dispatch(fetchRegistrationDataPhoto(args))
                break
              case 'passport-main-page-photo':
                dispatch(fetchPersonalDataPhoto(args))
                break
              case 'passport-selfie':
                dispatch(fetchPassportSelfieDataPhoto({ userId }))
                break
              default:
                break
            }
          }

          setFile(null)
          setPreview(null)
        },
      })
    )
  }

  return (
    <StyledImageWrapper>
      {!file ? (
        <>
          <input
            ref={input}
            type='file'
            id={`${type}-file`}
            accept={capture ? 'image/*' : '.png, .jpg, .jpeg, .bmp'}
            onChange={(e: any) => handleFiles(e?.target?.files)}
            capture={capture}
          />
          <StyledImage htmlFor={`${type}-file`}>
            <StyledPlus title={title}>
              {title && <StyledTitle>{title}</StyledTitle>}
              <div>+</div>
            </StyledPlus>
          </StyledImage>
        </>
      ) : (
        <StyledImage style={{ cursor: 'default' }}>
          <img src={preview} alt='img' />
          <StyledUploadImageLoaderWrapper>
            <SmallLoader />
          </StyledUploadImageLoaderWrapper>
        </StyledImage>
      )}
    </StyledImageWrapper>
  )
}

const toBase64: any = (file) =>
  new Promise((resolve, reject) => {
    const reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const spl = reader.result.split('base64,')
      resolve({
        image: spl[1],
        image_extension: `.${file.name.split('.').pop()}`.toLowerCase(),
      })
    }
    reader.onerror = (error) => reject(error)
  })
