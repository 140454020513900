export enum ContractType {
  allContracts = 'all_contracts',
  active = 'active',
  overdue = 'overdue',
  repayment_date = 'repayment_date',
}

export enum ContractTypeValue {
  all_contracts = 'all_contracts',
  active = 'active',
  overdue = 'overdue',
  repayment_date = 'repayment_date',
}

export enum ContractStatus {
  loan_issued = 'Активный',
  loan_overdue = 'Просрочен',
  loan_repayment_date = 'День погашения',
}

export enum ContractStatusCode {
  loan_issued = 'loan_issued',
  loan_overdue = 'loan_overdue',
  loan_repayment_date = 'loan_repayment_date',
}

type Nullable<T> = T | null

export const ContractStatusType = {
  all_contracts: [
    'active_credit',
    'loan_issued',
    'loan_extended',
    'loan_repayment_date',
    'period_paid',
    'loan_overdue',
  ],
  active: ['active_credit', 'loan_issued', 'loan_extended', 'loan_repayment_date', 'period_paid'],
  overdue: ['loan_overdue'],
  repayment_date: ['loan_repayment_date'],
}

export interface IPhoto {
  image_id: string
  user_id: string
  created_at: string
  image_type_id: number
  bucket_name: string
  object_name: string
  photo_url: string
  image_url?: string
}

export interface IImageLoanData {
  personalDataPhoto: IPhoto[]
  registrationDataPhoto: IPhoto[]
  agentDataPhoto: IPhoto[]
  passportSelfie: IPhoto[]
}

export interface IPhonesData {
  number: string
  number_type: string
  is_default: boolean
  is_active: boolean
  comment: string
}

export interface IContractPageData {
  pending: boolean
  error: boolean
  data: IContractListingItem[]
  totalCount: number
  iteration: number
  activePage: string
}
export interface IContractSlice {
  totalAmount: any
  all_contracts: IContractPageData
  active: IContractPageData
  overdue: IContractPageData
  repayment_date: IContractPageData
  passportData: Nullable<IPassportData>
  personalData: Nullable<IPersonalData>
  loanData: Nullable<ILoanData>
  userData: Nullable<IUserData>
  imageData: Nullable<IImageLoanData>
  phonesData: Nullable<Array<IPhonesData>>
  userLoans: Nullable<Array<{ [str: string]: any }>>
  isFetchingDept: boolean
  dataDept: ILoadDebtData[]
  isFetchingTransactions: boolean
  dataTransactions: ILoadTransactionsData[]
  commentsData: IComments
  payments: any
  loan_balance: any
  // listing: IContractListingItem[]
}

export interface IComments {
  isFetching: boolean
  isUpdating: boolean
  pending: boolean
  data: ICommentsData[]
}

export interface ICommentsData {
  username: string
  comment: string
  added_at: string
}

export interface ILoadDebtData {
  loan_id: string
  total_debt: number
  principal_debt: number
  interest_debt: number
  penny_debt: number
  frozen_interest_debt: number
  added_at: string
}
export interface ILoadTransactionsData {
  loan_id: string
  amount: number
  trans_type: string
  trans_date: string
}

export interface IContractRequest {
  data: IContractData[]
  total: number
}

export interface IContractData {
  [str: string]: any
}

export interface ISelectedContractData {
  contract_data: unknown
}
export interface IContractListingItem {
  admin_username: string[]
  agent_manager_username: string
  agent_username: string
  closed_count: string
  loan_statuses_status_name: string
  loans_loan_ask_sum: number
  loans_loan_date_create: string
  loans_loan_id: string
  loans_loan_number: string
  loans_loan_sum: number
  loans_user_id: string
  overdue_days: number
  passport_first_name: string
  passport_last_name: string
  passport_middle_name: string
  passport_number: string
  passport_serial: string
  registration_address: string
  requests_count: number
  users_login: string
  utm_source: string
}

export interface IPassportData {
  first_name: string
  last_name: string
  middle_name: string
  gender: string
  date_of_birth: string
  place_of_birth: string
  citizenship: ''
  serial: string
  number: string
  date_of_issue: string
  place_of_issue: string
  issued_department_url: string
  code_division: string
}

export interface IPersonalData {
  login: string
  date_register: string
  time_zone: string
  check_word: string
  maiden_name: string
  marital_status: string
  children_amount: string
  email: string
  additional_services: string
  criminal_records: string
  user_id: string
}

export interface ILoanData {
  loan_id: string
  loan_number: string
  loan_source: unknown
  loan_date_create: string
  loan_issue_date: string
  loan_status: string
  loan_ask_sum: number
  loan_ask_period: number
  loan_permitted_sum: string
  loan_permitted_period: string
  loan_settings: unknown
  loan_repeated: boolean
  requests_count: number
  credit_product: string
  loan_sum: string
  loans_loan_id: string
  product_percent_day: string
  is_third_transfer_approved: boolean
  is_transfer_bki_approved: boolean
}

export interface IUserData {
  user_id: string
  first_name: string
  last_name: string
  middle_name: string
  customer_id: number
}
