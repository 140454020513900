/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

export class ProjectClientsService extends ServiceBase {
  public static getClients(type): AxiosPromise {
    const url =
      type === 'inactive_status'
        ? `agent_api/installment/agent_users?inactive_status=true`
        : `agent_api/installment/agent_users`

    return this.get(url)
  }
}
