/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import { createSlice } from '@reduxjs/toolkit'
import { fetchClients } from './extra'

const initialState = {
  loadable: {
    all: true,
    inactive_status: true,
  },
  totalAmount: {
    pending: false,
    error: false,
    clients: 0,
  },
  clients: {
    all: [],
    inactive_status: [],
  },
}

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    resetClients: (state, { payload }) =>
      payload
        ? {
            ...state,
            clients: state.clients,
            [payload]: initialState[payload],
          }
        : {
            ...initialState,
            clients: state.clients,
          },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchClients.pending, () => {})
      .addCase(fetchClients.rejected, () => {})
      .addCase(fetchClients.fulfilled, (state, { payload }) => {
        state.loadable[payload?.type] = false
        state.clients[payload?.type] = payload?.data?.data
        state.totalAmount = { ...state.totalAmount, clients: payload?.data.length }
      })
  },
})

export const { resetClients } = clientsSlice.actions

export default clientsSlice.reducer
