/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { Formik } from 'formik'
import { GlobalState } from 'index'

import { Button, Select, Module } from 'components'
import {
  DatePickerField,
  InputField,
  normalizeValues,
  workType,
  employmentType,
  levelOfEducation,
  normalizeDateForApi,
  validationSchemaDetails,
  isValidForm,
  employeesNumbersDict,
  workingExperienceDict,
  AccessWrapper,
} from 'helpers'

import { rolesType } from 'constants/rtoles'
import { PermissionContext } from 'app/contexts'
import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'
import { fetchUserDetailsData, updateUserDetailsData } from 'features/loan/extra'
import { IPersonalData, IUserDetailsUpdateData } from 'features/loan/types'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import { StyledButtonBlock, StyledSelectBlock, StyledResetFilter } from './Modules.styles'
import { IModuleProps } from './types'
import { checkIsProductsAvailableForUserDetails, CreditProduct } from '../../../constants'

const moduleId = 'income'
export const UserDetails: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  claimsResolve,
  userId,
  creditProduct,
  claims,
}): JSX.Element => {
  const dispatch = useDispatch()
  const {
    userDetailsData: { isFetching, isUpdating, data },
  } = useSelector((state: GlobalState) => state.singleLoan)
  const { permissions } = useContext(PermissionContext)

  const [editable, setEditable] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<IPersonalData | unknown>({})
  const isOpen = openedModules.includes(moduleId)

  useEffect(() => {
    if (data && !editable) setInitialValues(data)
  }, [data, editable])

  const handleChangeEditable = (): void => setEditable(!editable)

  const handleCancel = (resetForm) => (): void => {
    resetForm()
    setEditable(!editable)
  }
  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = async (values): Promise<void> => {
    setInitialValues(values)

    const optionalValues = values
    const { education_level, employment_type, monthly_income, ...separatedValues } = optionalValues
    const body: IUserDetailsUpdateData = { education_level, employment_type, monthly_income }

    for (const [key, value] of Object.entries(optionalValues)) {
      if (value !== '' && value !== undefined) {
        if (key === 'next_salary_date' && (typeof value === 'string' || value instanceof Date)) {
          Object.assign(body, { [key]: normalizeDateForApi(value) })
        } else if (key === 'has_a_car') {
          Object.assign(body, { [key]: value === 'true' })
        } else if (key === 'work_experience' || key === 'last_work_experience') {
          Object.assign(body, { [key]: +value })
        } else Object.assign(body, { [key]: value })
      }
    }

    dispatch(
      updateUserDetailsData({
        id: userId,
        body,
        values,
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Данные не сохранены`,
            })
          )
        },
        onSuccess: () => {
          batch(() => {
            dispatch(fetchUserDetailsData({ id: userId }))
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Данные сохранены`,
              })
            )
          })

          setEditable(false)
        },
      })
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaDetails}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Занятость и доходы</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <AccessWrapper
                condition={permissions.includes(rolesType.regionalManager) || claimsResolve}
                access={[rolesType.agent, rolesType.regionalManager]}
              >
                <Module.Button handleChangeEditable={handleChangeEditable} />
              </AccessWrapper>
              <Module.Content>
                {isFetching && <Module.LoaderBlock />}
                {editable ? (
                  <Edit
                    {...formikProps}
                    claims={claims}
                    showAdditionalFields={checkIsProductsAvailableForUserDetails(creditProduct)}
                  />
                ) : (
                  <View
                    {...formikProps}
                    showAdditionalFields={checkIsProductsAvailableForUserDetails(creditProduct)}
                  />
                )}
              </Module.Content>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && (
                <div style={{ margin: '32px 0' }}>
                  <Button
                    disabled={isValidForm(errors) || isUpdating || !dirty}
                    type='standardBig'
                    onClick={() => dirty && handleSubmit()}
                    pending={isUpdating}
                  >
                    Сохранить
                  </Button>
                  <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue, errors, showAdditionalFields, claims } = props

  const suggestionListEmployment = Object.keys(employmentType).map((value) => ({
    label: employmentType[value],
    value,
  }))

  const suggestionListWorking = Object.keys(workType).map((value) => ({
    label: workType[value],
    value,
  }))

  const suggestionListEducation = Object.keys(levelOfEducation).map((value) => ({
    label: levelOfEducation[value],
    value,
  }))

  const suggestionWorkingExperience = Object.keys(workingExperienceDict).map((value) => ({
    label: workingExperienceDict[value],
    value,
  }))

  const suggestionListEmployeesNumbers = Object.keys(employeesNumbersDict).map((value) => ({
    label: employeesNumbersDict[value],
    value,
  }))

  const suggestionListHasCar = Object.keys({ true: 'есть', false: 'нет' }).map((value) => ({
    label: { true: 'есть', false: 'нет' }[value],
    value,
  }))

  const suggestionListIsSplitApproved = Object.keys({ true: 'да', false: 'нет' }).map((value) => ({
    label: { true: 'да', false: 'нет' }[value],
    value,
  }))

  return (
    <>
      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionListEducation}
            placeholder='Уровень образования'
            value={suggestionListEducation?.find((v) => v.value === values.education_level) || ''}
            onChange={(v) => setFieldValue('education_level', v.value)}
            error={!!errors?.education_level}
          />
          {values.education_level && (
            <StyledResetFilter onClick={() => setFieldValue('education_level', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>
      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionListEmployment}
            placeholder='Вид деятельности'
            value={suggestionListEmployment?.find((v) => v.value === values.activity_type) || ''}
            onChange={(v) => setFieldValue('activity_type', v.value)}
          />
          {values.activity_type && (
            <StyledResetFilter onClick={() => setFieldValue('activity_type', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>
      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionListWorking}
            placeholder='Тип занятости'
            value={suggestionListWorking?.find((v) => v.value === values.employment_type) || ''}
            onChange={(v) => setFieldValue('employment_type', v.value)}
            error={!!errors?.employment_type}
          />
          {values.employment_type && (
            <StyledResetFilter onClick={() => setFieldValue('employment_type', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>

      <Module.Column column={1}>
        <InputField
          name='monthly_income'
          onChange={setFieldValue}
          placeholder='Ежемесячный доход'
          value={values.monthly_income}
          type='number'
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='monthly_outcome'
          onChange={setFieldValue}
          placeholder='Ваши ежемесячные расходы'
          value={values.monthly_outcome}
          type='number'
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='other_income'
          onChange={setFieldValue}
          placeholder='Другие источники доходов'
          type='number'
          value={values.other_income}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <DatePickerField
          name='next_salary_date'
          onChange={setFieldValue}
          placeholder='Приблизительная дата следующей зарплаты'
          value={values.next_salary_date}
          format='dd.MM.yyyy'
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='average_annual_income'
          onChange={setFieldValue}
          placeholder='Среднемесячный доход за последние 12 месяцев'
          type='number'
          value={values.average_annual_income}
          noMove
          {...props}
        />
      </Module.Column>
      <>
        {showAdditionalFields && (
          <>
            <Module.Column column={1}>
              <InputField
                name='company_name'
                onChange={setFieldValue}
                placeholder='Название организации'
                value={values.company_name}
                noMove
                {...props}
              />
            </Module.Column>
            <Module.Column column={1}>
              <InputField
                name='company_address'
                onChange={setFieldValue}
                placeholder='Фактический адрес организации'
                value={values.company_address}
                noMove
                {...props}
              />
            </Module.Column>
            <Module.Column column={1}>
              <InputField
                name='work_position'
                onChange={setFieldValue}
                placeholder='Занимаемая должность'
                value={values.work_position}
                noMove
                {...props}
              />
            </Module.Column>
            <Module.Column column={1}>
              <StyledSelectBlock>
                <Select
                  data={suggestionListEmployeesNumbers}
                  placeholder='Количество сотрудников компании'
                  value={
                    suggestionListEmployeesNumbers?.find(
                      (v) => v.value === values.company_employees_amount
                    ) || ''
                  }
                  onChange={(v) => setFieldValue('company_employees_amount', v.value)}
                  error={!!errors?.company_employees_amount}
                />
                {values.company_employees_amount && (
                  <StyledResetFilter onClick={() => setFieldValue('company_employees_amount', '')}>
                    <CloseSVG />
                  </StyledResetFilter>
                )}
              </StyledSelectBlock>
            </Module.Column>
            <Module.Column column={1}>
              <InputField
                name='company_chief_name'
                onChange={setFieldValue}
                placeholder='Руководитель компании'
                value={values.company_chief_name}
                noMove
                {...props}
              />
            </Module.Column>
            <Module.Column column={1}>
              <StyledSelectBlock>
                <Select
                  data={suggestionWorkingExperience}
                  placeholder='Опыт работы (общий)'
                  value={
                    suggestionWorkingExperience?.find((v) => v.value === values.work_experience) ||
                    ''
                  }
                  onChange={(v) => setFieldValue('work_experience', v.value)}
                  error={!!errors?.work_experience}
                />
                {values.work_experience && (
                  <StyledResetFilter onClick={() => setFieldValue('work_experience', '')}>
                    <CloseSVG />
                  </StyledResetFilter>
                )}
              </StyledSelectBlock>
            </Module.Column>
            <Module.Column column={1}>
              <StyledSelectBlock>
                <Select
                  data={suggestionWorkingExperience}
                  placeholder='Опыт на последнем месте работы'
                  value={
                    suggestionWorkingExperience?.find(
                      (v) => v.value === values.last_work_experience
                    ) || ''
                  }
                  onChange={(v) => setFieldValue('last_work_experience', v.value)}
                  error={!!errors?.last_work_experience}
                />
                {values.last_work_experience && (
                  <StyledResetFilter onClick={() => setFieldValue('last_work_experience', '')}>
                    <CloseSVG />
                  </StyledResetFilter>
                )}
              </StyledSelectBlock>
            </Module.Column>
            <Module.Column column={1}>
              <StyledSelectBlock>
                <Select
                  data={suggestionListHasCar}
                  placeholder='Наличие машины'
                  value={
                    suggestionListHasCar?.find((v) => v.value === values.has_a_car.toString()) || ''
                  }
                  onChange={(v) => setFieldValue('has_a_car', v.value)}
                  error={!!errors?.has_a_car}
                />
                {values.has_a_car && (
                  <StyledResetFilter onClick={() => setFieldValue('has_a_car', '')}>
                    <CloseSVG />
                  </StyledResetFilter>
                )}
              </StyledSelectBlock>
            </Module.Column>
            <Module.Column column={1}>
              <InputField
                type='number'
                name='monthly_credit_obligations'
                onChange={setFieldValue}
                placeholder='Ежемесячные платежи по кредитам'
                value={values.monthly_credit_obligations}
                noMove
                {...props}
              />
            </Module.Column>
          </>
        )}
      </>
      {claims === rolesType.underwriter && (
        <Module.Column column={1}>
          <StyledSelectBlock>
            <Select
              data={suggestionListIsSplitApproved}
              placeholder='Разрешено дробление займа'
              value={
                suggestionListIsSplitApproved?.find(
                  (v) => v.value?.toString() === values.is_split_approved.toString()
                ) || ''
              }
              onChange={(v) => setFieldValue('is_split_approved', v.value)}
              error={!!errors?.is_split_approved}
            />
            {values.is_split_approved && (
              <StyledResetFilter onClick={() => setFieldValue('is_split_approved', '')}>
                <CloseSVG />
              </StyledResetFilter>
            )}
          </StyledSelectBlock>
        </Module.Column>
      )}
    </>
  )
}

const View = (props): JSX.Element => {
  const { values, showAdditionalFields } = props

  return (
    <>
      <>
        <Module.Column column={1}>
          <Module.Title>Уровень образования</Module.Title>
          <Module.Value>{normalizeValues(values.education_level, 'education_level')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Вид деятельности</Module.Title>
          <Module.Value>{normalizeValues(values.activity_type, 'activity_type')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Тип занятости</Module.Title>
          <Module.Value>{normalizeValues(values.employment_type, 'employment_type')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Ежемесячный доход</Module.Title>
          <Module.Value styles='Module.ValuePhone'>
            {normalizeValues(values.monthly_income, 'sum')}
          </Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Ваши ежемесячные расходы</Module.Title>
          <Module.Value>{normalizeValues(values.monthly_outcome)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Другие источники доходов</Module.Title>
          <Module.Value>{normalizeValues(values.other_income)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Приблизительная дата следующей зарплаты</Module.Title>
          <Module.Value>{normalizeValues(values.next_salary_date, 'date')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Среднемесячный доход за последние 12 месяцев</Module.Title>
          <Module.Value>{normalizeValues(values.average_annual_income)}</Module.Value>
        </Module.Column>
        <>
          {showAdditionalFields && (
            <>
              <Module.Column column={1}>
                <Module.Title>Название организации</Module.Title>
                <Module.Value>{normalizeValues(values.company_name)}</Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Фактический адрес организации</Module.Title>
                <Module.Value>{normalizeValues(values.company_address)}</Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Занимаемая должность</Module.Title>
                <Module.Value>{normalizeValues(values.work_position)}</Module.Value>
              </Module.Column>

              <Module.Column column={1}>
                <Module.Title>Количество сотрудников компании</Module.Title>
                <Module.Value>
                  {normalizeValues(values.company_employees_amount, 'employment_numbers')}
                </Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Руководитель компании</Module.Title>
                <Module.Value>{normalizeValues(values.company_chief_name)}</Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Опыт работы (общий)</Module.Title>
                <Module.Value>{normalizeValues(values.work_experience)}</Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Опыт на последнем месте работы</Module.Title>
                <Module.Value>{normalizeValues(values.last_work_experience)}</Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Наличие машины</Module.Title>
                <Module.Value>{values.has_a_car && values.has_a_car ? 'есть' : 'нет'}</Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Ежемесячные платежи по кредитам</Module.Title>
                <Module.Value>{normalizeValues(values.monthly_credit_obligations)}</Module.Value>
              </Module.Column>
            </>
          )}
        </>
        <Module.Column column={1}>
          <Module.Title>Разрешено дробление займа</Module.Title>
          <Module.Value>{values?.is_split_approved ? 'да' : 'нет'}</Module.Value>
        </Module.Column>
      </>
    </>
  )
}
