/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import {
  createLoanComments,
  fetchAgentDataPhoto,
  fetchContractPassportSelfieDataPhoto,
  fetchContracts,
  fetchContractsCount,
  fetchContractsUserPassportData,
  fetchContractsUserPersonalData,
  fetchLoanDebt,
  fetchLoanPageById,
  fetchLoanTransactions,
  fetchPersonalDataPhoto,
  fetchPhonesData,
  fetchRegistrationDataPhoto,
  fetchUserLoans,
  getLoanComments,
  getPaymentsResult,
  updateLoanComments,
} from './extra'
import { IContractSlice, IContractRequest, IPhoto, ILoanData } from './types'

const initialState: IContractSlice = {
  totalAmount: {
    pending: false,
    error: false,
    all_contracts: 0,
    active: 0,
    overdue: 0,
  },
  all_contracts: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  active: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  overdue: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  repayment_date: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  passportData: null,
  personalData: null,
  loanData: null,
  userData: null,
  loan_balance: null,
  imageData: {
    personalDataPhoto: [],
    registrationDataPhoto: [],
    agentDataPhoto: [],
    passportSelfie: [],
  },
  phonesData: null,
  userLoans: null,
  isFetchingDept: false,
  dataDept: [],
  isFetchingTransactions: false,
  dataTransactions: [],
  commentsData: {
    isFetching: true,
    isUpdating: false,
    pending: false,
    data: [],
  },
  payments: { isFetching: true, data: [] },
}

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    resetContracts: (state, { payload }) =>
      payload
        ? {
            ...state,
            totalAmount: state.totalAmount,
            [payload]: initialState[payload],
          }
        : {
            ...initialState,
            totalAmount: state.totalAmount,
          },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContracts.pending, (state, payload: any) => {
        const {
          meta: {
            arg: { type },
          },
        } = payload
        state[type].pending = true
      })
      .addCase(fetchContracts.rejected, (state, payload: any) => {
        const {
          meta: {
            arg: { type },
          },
        } = payload
        state[type].error = true
        state[type].pending = false
        state[type].data = {}
      })
      .addCase(
        fetchContracts.fulfilled,
        (state, { payload }: PayloadAction<IContractRequest | any>) => {
          const { data, type, iteration, refresh } = payload
          state[type].pending = false
          const normalizeData = keyBy(data.data, 'loans_loan_id')
          state[type].data = !refresh
            ? {
                ...(state[type].data || {}),
                ...normalizeData,
              }
            : normalizeData
          state[type].totalCount = data.total
          state[type].finish = !data.count
          state[type].iteration = iteration || state[type].iteration + 1
        }
      )

      .addCase(fetchContractsCount.pending, (state) => {
        state.totalAmount.pending = true
      })
      .addCase(fetchContractsCount.rejected, (state) => {
        state.totalAmount.error = true
        state.totalAmount.pending = false
      })
      .addCase(
        fetchContractsCount.fulfilled,
        (state, { payload }: PayloadAction<IContractRequest | any>) => {
          const { all_contracts, active, overdue } = payload
          state.totalAmount = {
            all_contracts,
            active,
            overdue,
            pending: false,
          }
        }
      )
      .addCase(fetchContractsUserPassportData.pending, () => {})
      .addCase(fetchContractsUserPassportData.rejected, () => {})
      .addCase(fetchContractsUserPassportData.fulfilled, (state, { payload }) => {
        state.passportData = payload?.data?.passport
      })
      .addCase(fetchContractsUserPersonalData.pending, () => {})
      .addCase(fetchContractsUserPersonalData.rejected, () => {})
      .addCase(fetchContractsUserPersonalData.fulfilled, (state, { payload }) => {
        state.personalData = payload?.data
      })
      .addCase(fetchLoanPageById.pending, () => {
        // state.isFetchingLoanPage = true
      })
      .addCase(fetchLoanPageById.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loanData = payload.loan_data
        state.userData = payload.user_data
        state.loan_balance = payload.loan_balance
      })
      .addCase(fetchPersonalDataPhoto.pending, (state) => {
        // state.loanImageDataFetch.personalDataPhotoFetching = true
      })
      .addCase(
        fetchPersonalDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          // state.loanImageDataFetch.personalDataPhotoFetching = false
          state.imageData.personalDataPhoto = payload
        }
      )

      .addCase(fetchRegistrationDataPhoto.pending, (state) => {
        // state.loanImageDataFetch.registrationDataPhotoFetching = true
      })
      .addCase(
        fetchRegistrationDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          // state.loanImageDataFetch.registrationDataPhotoFetching = false
          state.imageData.registrationDataPhoto = payload
        }
      )

      .addCase(fetchAgentDataPhoto.pending, (state) => {
        // state.loanImageDataFetch.agentDataPhotoFetching = true
      })
      .addCase(
        fetchAgentDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          // state.loanImageDataFetch.agentDataPhotoFetching = false
          state.imageData.agentDataPhoto = payload
        }
      )
      .addCase(fetchAgentDataPhoto.rejected, (state) => {
        // state.loanImageDataFetch.agentDataPhotoFetching = false
      })

      .addCase(fetchContractPassportSelfieDataPhoto.pending, (state) => {})
      .addCase(
        fetchContractPassportSelfieDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          state.imageData.passportSelfie = payload
        }
      )
      .addCase(fetchPhonesData.fulfilled, (state, { payload }) => {
        state.phonesData = payload
      })
      .addCase(fetchUserLoans.fulfilled, (state, { payload }: PayloadAction<Array<ILoanData>>) => {
        state.userLoans = payload
      })
      .addCase(fetchLoanTransactions.pending, (state) => {
        state.isFetchingDept = true
      })
      .addCase(fetchLoanTransactions.rejected, (state) => {
        state.isFetchingDept = false
      })
      .addCase(fetchLoanTransactions.fulfilled, (state, { payload }) => {
        state.isFetchingDept = false
        state.dataTransactions = payload.data
      })
      .addCase(fetchLoanDebt.pending, (state) => {
        state.isFetchingDept = true
      })
      .addCase(fetchLoanDebt.rejected, (state) => {
        state.isFetchingDept = false
      })
      .addCase(fetchLoanDebt.fulfilled, (state, { payload }) => {
        state.isFetchingDept = false
        state.dataDept = payload.data
      })
      // get comments
      .addCase(getLoanComments.pending, (state) => {
        state.commentsData.isFetching = true
      })
      .addCase(getLoanComments.fulfilled, (state, { payload }) => {
        state.commentsData.isFetching = false
        state.commentsData.data = payload
      })
      .addCase(getLoanComments.rejected, (state) => {
        state.commentsData.isFetching = false
      })
      // create comments
      .addCase(createLoanComments.pending, (state) => {
        state.commentsData.pending = true
      })
      .addCase(createLoanComments.fulfilled, (state) => {
        state.commentsData.pending = false
      })
      .addCase(createLoanComments.rejected, (state) => {
        state.commentsData.pending = false
      })
      .addCase(updateLoanComments.pending, (state) => {
        state.commentsData.isUpdating = true
      })
      .addCase(updateLoanComments.fulfilled, (state) => {
        state.commentsData.isUpdating = false
      })
      .addCase(updateLoanComments.rejected, (state) => {
        state.commentsData.isUpdating = false
      })

      .addCase(getPaymentsResult.pending, (state) => {
        state.payments.isFetching = true
      })
      .addCase(getPaymentsResult.fulfilled, (state, { payload }) => {
        state.payments.isFetching = false
        state.payments.data = payload
      })
      .addCase(getPaymentsResult.rejected, (state) => {
        state.payments.isFetching = false
      })
  },
})

export const { resetContracts } = contractsSlice.actions

export default contractsSlice.reducer
