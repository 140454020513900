import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectClientsService } from '../../services/clientsService'

export const fetchClients = createAsyncThunk<any, { type: any }>(
  'contracts/loadClients',
  async ({ type }) => {
    const response = await ProjectClientsService.getClients(type).then((data) => ({
      data,
      type,
    }))

    return response
  }
)
