/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import StickyBox from 'react-sticky-box'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

import {
  Button,
  EmptyValueReplacer,
  Loader,
  ModalConfirm,
  ScrollToTop,
  Select,
  TableHead,
} from 'components'
import { changeAgent, removeFromAgent } from 'features/agentLoans/extra'
import { fetchClients } from 'features/clients/extra'
import { useGetAgentUserNamesWithIds } from 'features/dict/dictSelectors'

import { BODY_STRUCTURE, COLUMN_TEMPLATE, HEAD_STRUCTURE } from './tableStructure'
import {
  StyledRowWrapper,
  StyledTitle,
  StyledValue,
  StyledActionProvider,
  StyledActionChange,
  StyledSelectAgent,
} from './Table.styles'

import { StaledContainer, StyledEmptyLabel, StyledTableWrapper } from '../ClientsList.styles'

import { HiOutlineRefresh } from 'react-icons/hi'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { ClientView } from './MobileView'
import { getAdminFio } from 'helpers'
import { GlobalState } from 'index'
import { StyledInfiniteLoader } from 'pages/UnderwriterList/Underwriter.styles'

const initialModalState = { title: '', description: '', action: null, show: false }

interface IShowModal {
  title: string
  description: string
  show: boolean
  variant?: 'delete' | 'unassign'
}

export const Table: any = ({ clients, type, loadable }): JSX.Element => {
  const dispatch = useDispatch()
  const dictData = useSelector((state: GlobalState) => state.dict)
  const agentUserNamesWithIds = useGetAgentUserNamesWithIds() || []
  const [showModal, setShowModal] = useState<IShowModal>(initialModalState)
  const [assignAgent, setAssignAgent] = useState<string>('')
  const [customerPhone, setCustomerPhone] = useState<string>('')

  const AgentUsernamesList = agentUserNamesWithIds.map((value) => ({
    label: getAdminFio(value.username, dictData),
    value: value.username,
  }))

  const ActionProvider = (props) => {
    const { phone } = props
    return (
      <StyledActionProvider>
        <StyledActionChange onClick={() => handleChangeAgentModal(phone)}>
          <HiOutlineRefresh />
        </StyledActionChange>
      </StyledActionProvider>
    )
  }

  const handleDeleteModal = (phone): void => {
    setCustomerPhone(phone)
    setShowModal({
      show: true,
      title: 'Открепление клиента.',
      description: 'Открепить клиента ?',
      variant: 'delete',
    })
  }

  const handleChangeAgentModal = (phone): void => {
    setCustomerPhone(phone)
    setShowModal({
      show: true,
      title: 'Перезакрепление клиента.',
      description: 'Выберите агента для перезакрепления',
      variant: 'unassign',
    })
  }

  const fetchDeleteFromAgent = () => {
    dispatch(
      removeFromAgent({
        phone: customerPhone,
        errorAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Открепление произошло с ошибкой`,
            })
          )
        },
        successAction: () => {
          setShowModal(initialModalState)
          batch(() => {
            dispatch(fetchClients({ type }))
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Открепление прошло успешно`,
              })
            )
          })
        },
      })
    )
  }

  const fetchAgentChange = () => {
    dispatch(
      changeAgent({
        phone: customerPhone,
        agent_id: agentUserNamesWithIds.find((x) => x.username === assignAgent)?.admin_id || '',
        errorAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Открепление произошло с ошибкой`,
            })
          )
        },
        successAction: () => {
          setShowModal(initialModalState)
          batch(() => {
            dispatch(fetchClients({ type }))
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Открепление прошло успешно`,
              })
            )
          })
        },
      })
    )
  }

  return (
    <div style={{ overflow: 'auto' }} id='scrollable'>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={() => setShowModal({ ...showModal, show: false })}
        onClose={() => setShowModal({ ...showModal, show: false })}
        description={showModal.description}
        title={showModal.title}
        buttons={false}
        content={() => (
          <>
            {showModal.variant === 'delete' ? (
              <>
                <div>
                  <Button type='standardBig' onClick={fetchDeleteFromAgent}>
                    Открепить
                  </Button>
                  <Button type='emptyBig' onClick={() => setShowModal(initialModalState)}>
                    Отмена
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <StyledSelectAgent>
                    <Select
                      data={AgentUsernamesList}
                      placeholder='Агент'
                      value={AgentUsernamesList?.find((v) => v.value === assignAgent) || ''}
                      onChange={({ value }) => setAssignAgent(value)}
                    />
                  </StyledSelectAgent>
                  <Button type='standardBig' onClick={fetchAgentChange} disabled={!assignAgent}>
                    Перезакрепить
                  </Button>
                  <Button type='emptyBig' onClick={() => setShowModal(initialModalState)}>
                    Отмена
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      />
      <ScrollToTop scrollTo='#scrollable' />
      <StickyBox>
        <TableHead structure={HEAD_STRUCTURE} type={type} template={COLUMN_TEMPLATE[type]} />
      </StickyBox>
      <StyledTableWrapper>
        {loadable[type] ? (
          <StyledInfiniteLoader>
            <Loader />
          </StyledInfiniteLoader>
        ) : (
          <>
            {clients && clients[type]?.length ? (
              clients[type].map((client: any, i) => (
                <StaledContainer key={`${client.user_id}-${i}`}>
                  <>
                    <StyledRowWrapper template={COLUMN_TEMPLATE[type]}>
                      {BODY_STRUCTURE[type]?.map((column) => (
                        <StyledValue key={column.id} onClick={null}>
                          <StyledTitle pending={client.pending}>
                            {column?.action && <ActionProvider {...client} />}
                            {column.value
                              ? column.value(client)
                              : client[column.name] ||
                                (column.action ? null : <EmptyValueReplacer />)}
                          </StyledTitle>
                        </StyledValue>
                      ))}
                      <ClientView
                        client={client}
                        handleChangeAgentModal={handleChangeAgentModal}
                        handleDeleteModal={handleDeleteModal}
                      />
                    </StyledRowWrapper>
                  </>
                </StaledContainer>
              ))
            ) : (
              <StyledEmptyLabel>{!clients?.pending && <p>Ничего не найдено</p>}</StyledEmptyLabel>
            )}
          </>
        )}
      </StyledTableWrapper>
    </div>
  )
}
